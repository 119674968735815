import { Colors, ColorTypes } from '../../tokens/index';

/**
 * Returns a color from the colors theme object based on the id
 * @param  {string} id - Identifier for the color in the configuration map.
 * @param {string} type - Identifier for the type in the configuration map.
 * @returns {string} Resulting color from configuration map
 * @example color: ${color('neutral', 'shade')};
 */
const color = (id: Colors, type: ColorTypes): string => `var(--colors-${id}-${type})`;

export default color;
