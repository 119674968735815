import { Breakpoints } from '../../tokens/index';
import px2rem from './px2rem';

/**
 * Simple CSS MediaQuery.
 * Expects a breakpoint (string or number) and min or max string (default min).
 * @param {string} id - Identifier for the breakpoint in the configuration file
 * @param {string} minMax - Default: min. String for the media-query type
 * @returns {string} Media query definition
 * @example ${mq('b375', 'max')} { css styles go here };
 */
export const mq =
  (id: Breakpoints, minMax = 'min') =>
  ({ theme }: any) =>
    `@media (${minMax}-width: ${px2rem(theme.breakpoints[id])})`;

type PropertyObject = {
  default?: string | number;
  s?: string | number;
  m?: string | number;
  l?: string | number;
};

type PropertyTransform = (value: string | number) => string | number;

export const getProperty = (
  property: string,
  propertyObject: PropertyObject,
  transform?: PropertyTransform
): string => {
  let columns = '';

  Object.keys(propertyObject).forEach(key => {
    const item = propertyObject[key as keyof typeof propertyObject];
    const value = transform && item ? transform(item) : item;

    const string =
      key === 's'
        ? `${property}: ${value};`
        : `${mq(key as Breakpoints)} { ${property}: ${value};}`;

    columns += string;
  });

  return columns;
};
