import { css } from 'styled-components';
import { mq } from './breakpoint';
import px2rem from './px2rem';
import { Breakpoints, theme } from '../../tokens';

/* TODO: cleanup theme usage  */

// Container with max-width
export const getMaxWidthContainer = () => css`
  width: 100%;
  max-width: ${px2rem(theme.maxWidth)};
  margin: 0 auto;
`;

// Outer grid margins
export const getGridPaddings = () => css`
  padding-right: 28px;
  padding-left: 28px;

  ${mq('l')} {
    padding-right: 60px;
    padding-left: 60px;
  }
`;

type ColumnsConfig = {
  [key: string]: number;
};

const getGridColumns =
  (columnsConfig: ColumnsConfig) =>
  ({ theme }: any) => {
    let columnsCSS = '';

    Object.keys(columnsConfig).forEach(key => {
      const item = columnsConfig[key];

      const string =
        key === 's'
          ? `grid-template-columns: repeat(${item}, 1fr);`
          : `${mq(key as any)({ theme })} { grid-template-columns: repeat(${item}, 1fr);}`;

      columnsCSS += string;
    });

    return columnsCSS;
  };

// Get CSS Grid Column Gap
const getGridColumnGap =
  () =>
  ({ theme }: any) => {
    let columnsGapCSS = '';

    Object.keys(theme.grid.gutters).forEach(key => {
      const gutter = `${theme.grid.gutters[key]}px`;

      const string =
        key === 's'
          ? `grid-column-gap: ${gutter};`
          : `${mq(key as any)({ theme })} { grid-column-gap: ${gutter};}`;

      columnsGapCSS += string;
    });

    return columnsGapCSS;
  };

export function getGridGutter(key: Breakpoints): string {
  return `${theme.grid.gutters[key]}px`;
}

export const getGrid = (gridPaddings = true, customGrid = null) => {
  const columns = getGridColumns(customGrid || theme.grid.columns);
  const columnGap = getGridColumnGap();

  return css`
    display: grid;
    ${columnGap};
    ${columns};
    ${gridPaddings && getGridPaddings()}
  `;
};
