import { createGlobalStyle } from 'styled-components';
import sanitize from './sanitize';

import { color, space } from './tools';

const GlobalStyles = createGlobalStyle`
  ${sanitize}

  @font-face {
    font-family: 'Forta';
    font-weight: normal;
    font-display: swap;
    src: url(/fonts/forta.woff2) format('woff2');
  }
  
  button[data-cookiefirst-outline-accent-color] {
    cursor: pointer;
    margin-top: ${space(1)};
    margin-right: ${space(2)};
    background: ${color('blue', 'color')};
    color: ${color('neutral', 'shade')};
    padding: ${space(0.75)} ${space(1)};

    &:hover {
      color: ${color('blue', 'color')};
      background: ${color('neutral', 'shade')};
      border: 1px solid ${color('blue', 'color')};
    }
  }
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html,
  body {
    width: 100%;
  }

  html {
    text-size-adjust: 100%;
    text-rendering: optimizeSpeed;
    font-size: 100%;
  }

  body {
    font-family: Helvetica, sans-serif;
    font-display: swap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    background: ${color('neutral', 'background')};
    color: ${color('neutral', 'color')};
  }

  input,
  textarea,
  select,
  button {
    font-family: Helvetica, sans-serif;
  }

  img {
    /* stylelint-disable */
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    /* stylelint-enable */
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  img {
    image-rendering: crisp-edges;
  }

  html,
  body,
  div,
  article,
  section,
  main,
  footer,
  header,
  form,
  fieldset,
  legend,
  pre,
  code,
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ul,
  ol,
  li,
  dl,
  dt,
  dd,
  textarea,
  input[type='email'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='text'],
  input[type='url'] {
    box-sizing: border-box;
  }

  figure {
    margin: 0;
  }

  main {
    width: 100%;
    display: block;
    position: relative;
    background: #fff;
  }

  p {
    margin: 0;
  }

  i {
    font-style: italic;
  }

  b,
  strong {
    font-weight: 700;
  }

  @media print {
    @page {
      margin: 2cm;
    }

    *,
    *:before,
    *:after {
      background: transparent !important;
      color: #000 !important;
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: ' (' attr(href) ')';
    }

    abbr[title]:after {
      content: ' (' attr(title) ')';
    }
    /*
    * Don't show links that are fragment identifiers
    */

    a[href^='#']:after,
    a[href^='javascript:']:after {
      content: '';
    }

    pre {
      white-space: pre-wrap !important;
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    /*
    * Printing Tables:
    * http://css-discuss.incutio.com/wiki/Printing_Tables
    */

    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }

  button {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    background: transparent;
  }
`;

export default GlobalStyles;
