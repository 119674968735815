export const theme: ThemeInterface = {
  globalTheme: 'blue',
  maxWidth: 1440,
  breakpoints: {
    s: 640,
    m: 768,
    l: 1024
  },
  colors: {
    blue: {
      color: '#194d8e',
      shade: '#7aa0cc',
      background: '#D2E9F6'
    },
    red: {
      color: '#d80043',
      shade: '#f499b5',
      background: '#F8E6E3'
    },
    green: {
      color: '#687300',
      shade: '#c9d933',
      background: '#F9F2D6'
    },
    brown: {
      color: '#9c5748',
      shade: '#f1876f',
      background: '#E9F0C8'
    },
    neutral: {
      color: '#2B2C2C',
      shade: '#fdfdfa',
      background: '#f5f7fa'
    }
  },
  grid: {
    gutters: {
      s: 6,
      m: 12,
      l: 12
    },
    columns: {
      s: 12,
      m: 12,
      l: 12
    },
    debugColors: '#7aa0cc'
  },
  spacingBase: 12
};

export type Colors = keyof typeof theme.colors;
export type ColorTypes = keyof typeof theme.colors.blue;
export type Breakpoints = keyof typeof theme.breakpoints;
export type Grid = keyof typeof theme.grid;

export interface ThemeInterface {
  globalTheme: 'blue' | 'red' | 'green' | 'brown';
  maxWidth: number;
  breakpoints: {
    s: number;
    m: number;
    l: number;
  };
  colors: {
    blue: {
      color: string;
      shade: string;
      background: string;
    };
    red: {
      color: string;
      shade: string;
      background: string;
    };
    green: {
      color: string;
      shade: string;
      background: string;
    };
    brown: {
      color: string;
      shade: string;
      background: string;
    };
    neutral: {
      color: string;
      shade: string;
      background: string;
    };
  };
  grid: {
    gutters: {
      s: number;
      m: number;
      l: number;
    };
    columns: {
      s: number;
      m: number;
      l: number;
    };
    debugColors: string;
  };
  spacingBase: number;
}
