import { ThemeInterface, Breakpoints } from '../../tokens/index';
import { mq } from '../tools/breakpoint';
import px2rem from '../tools/px2rem';

export const buildCSSVar = (path: string[], value: number | string): string => {
  const cssValue =
    typeof value === 'string' && value.endsWith('px')
      ? px2rem(parseFloat(value.split('px')[0]))
      : value;

  return `--${path.join('-')}: ${cssValue};`;
};

export const getCSSVar = (path: string[]): string => `var(--${path.join('-')})`;

export const buildThemeCSSVars = (theme: ThemeInterface): string => {
  let vars = '';
  const breakpointVars: { [key: string]: string } = {};

  const loopThroughThemeObject = (obj: any, path: string[] = []) => {
    Object.keys(obj).forEach(k => {
      if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
        loopThroughThemeObject(obj[k], [...path, k]);
      } else if (Array.isArray(obj[k])) {
        obj[k].map((item: { value: string | number; breakpoint: string }) => {
          const newValue = buildCSSVar([...path, k], item.value);

          if (item.breakpoint === 'default') {
            const returnNewVars = vars + newValue;
            return returnNewVars;
          }

          if (typeof breakpointVars[item.breakpoint] === 'undefined') {
            return Object.assign(breakpointVars, {
              [item.breakpoint]: newValue
            });
          }

          const returnNewVars = breakpointVars[item.breakpoint] + newValue;
          return returnNewVars;
        });
      } else {
        vars += buildCSSVar([...path, k], obj[k]);
      }
    });
  };

  loopThroughThemeObject(theme, []);

  return `
    ${vars}

    ${Object.keys(breakpointVars).map(
      item => `${mq(item as Breakpoints)} {
        ${breakpointVars[item]}
      }
    `
    )}
  `;
};
