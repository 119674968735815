/**
 * Returns spacingBase multiplied by multiplier
 * @example padding: ${space(2)};
 * @param {number} multiplier Spacing Multiplier value
 * @returns {array} Rem value
 */
const space = (multiplier?: number | number[]): string => {
  if (typeof multiplier === 'undefined') return 'calc(var(--spacingBase) * 1px)';
  if (typeof multiplier === 'number') return `calc(var(--spacingBase) * ${multiplier}px)`;

  return multiplier.map(item => `calc(var(--spacingBase) * ${item}px)`).join(' ');
};

export default space;
