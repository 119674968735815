import React, { ReactChild } from 'react';
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components';

import { theme as cssVars, ThemeInterface } from '../../tokens/index';
import { buildThemeCSSVars } from './buildTheme';
import GlobalStyles from '../global';

export type ThemeProviderProps = { children: ReactChild; theme: ThemeInterface };

const ThemeVars = createGlobalStyle`body {${buildThemeCSSVars(cssVars)}};`;

const ThemeProvider = ({ children, theme }: ThemeProviderProps): JSX.Element => (
  <StyledThemeProvider theme={theme}>
    <ThemeVars />
    <GlobalStyles />
    {children}
  </StyledThemeProvider>
);

export default ThemeProvider;
