/* eslint-disable react/prop-types */
import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';

// import getConfig from 'next/config';
// import * as Sentry from '@sentry/node';
// import { RewriteFrames } from '@sentry/integrations';
import type { AppProps } from 'next/app';

import { theme } from '../tokens';
import ThemeProvider from '../stylesheets/theme/ThemeProvider';

const CF_INIT_EVENT = 'cf_init';
const CF_CONSENT_EVENT = 'cf_consent';
// import { ApolloProvider } from '@apollo/client';

// import { useApollo } from '../lib/apollo';

export type CustomAppProps = {
  children: ReactNode;
} & AppProps;

type CfConsent = {
  necessary: boolean;
};

declare global {
  interface Window {
    _paq: any;
    CookieFirst: {
      acceptCategory: (cookiefirstCategory: 'advertising' | 'functional') => void;
      renderEmbeds: () => void;
      consent: {
        necessary: boolean;
        performance: boolean;
        functional: boolean;
        advertising: boolean;
      };
    };
  }
}

// const {
//   publicRuntimeConfig: { NODE_ENV, SENTRY_DSN, SENTRY_ENVIRONMENT }
// } = getConfig();

// Sentry: initialization
// if (SENTRY_DSN && NODE_ENV === 'production') {
//   const config = getConfig();
//   const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
//   Sentry.init({
//     integrations: [
//       new RewriteFrames({
//         iteratee: frame => {
//           const f = frame;
//           f.filename?.replace(distDir, 'app:///_next');
//           return f;
//         }
//       })
//     ],
//     environment: SENTRY_ENVIRONMENT,
//     dsn: SENTRY_DSN
//   });
// }

const App: React.FC<AppProps> = ({ Component, pageProps }: AppProps): JSX.Element => {
  const router = useRouter();
  const [cfConsent, setCfConsent] = useState<CfConsent | null>(null);

  const handleConsentUpdate = useCallback(e => {
    setCfConsent(e?.detail);
  }, []);

  const handleConsentInit = useCallback(() => {
    if (!window.CookieFirst) {
      return;
    }
    setCfConsent(window.CookieFirst.consent || {});
  }, []);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (window && window._paq) {
        window._paq.push(['setCustomUrl', url]);
        window._paq.push(['setDocumentTitle', document.title]);
        window._paq.push(['trackPageView']);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);
  }, []);

  useEffect(() => {
    window.addEventListener(CF_INIT_EVENT, handleConsentInit);
    window.addEventListener(CF_CONSENT_EVENT, handleConsentUpdate);

    return () => {
      window.removeEventListener(CF_INIT_EVENT, handleConsentInit);
      window.removeEventListener(CF_CONSENT_EVENT, handleConsentUpdate);
    };
  }, []);

  return (
    // const apolloClient = useApollo(pageProps.initialApolloState);

    <ThemeProvider theme={{ ...theme, globalTheme: 'blue' }}>
      <>
        <Head>
          <meta name="viewport" content="width=device-width" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <link rel="apple-touch-icon" sizes="180x180" href="/icons/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/icons/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/icons/favicon-16x16.png" />
          <link rel="manifest" href="/icons/site.webmanifest" crossOrigin="use-credentials" />
          <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#000000" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff" />
          <link
            rel="preload"
            href="/fonts/forta.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />

          <script
            type="text/plain"
            data-cookiefirst-category="advertising"
            dangerouslySetInnerHTML={{
              __html: `var _paq = window._paq = window._paq || [];
              /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
              _paq.push(['trackPageView']);
              _paq.push(['enableLinkTracking']);
              (function() {
                var u="//piwik.lola.fork.corpex-kunden.de/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', '1']);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
              })();`
            }}
          />
          {cfConsent?.necessary && (
            <style
              type="text/css"
              dangerouslySetInnerHTML={{
                __html: `@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=e76e88bd-35a6-49a6-b123-17bffbd33f6e&fontids=5591097,5591111");
              @font-face {
                font-family: 'DIN2014-Regular';
                font-weight: normal;
                font-display: swap;
                src: url(/fonts/6ceed230-b2b3-4422-b048-4aa11687430a.woff2) format('woff2');
              }`
              }}
            />
          )}
        </Head>

        {/* <ApolloProvider client={apolloClient}> */}
        <Component {...pageProps} />
        {/* </ApolloProvider> */}
      </>
    </ThemeProvider>
  );
};

export default App;
