export type AdaptiveSizeProps = {
  size: number[];
  width: number[];
  breakpoints: number;
  lineHeight?: number[];
  property?: string;
};

const interpolateSize = (width: number, from: number, to: number, min: number, max: number) =>
  min + (max - min) * ((width - from) / (to - from));

/**
 * Returns adaptive css font-size string
 * @param  {object} config - Config object with size, lineHeight, width and breakpoints key
 * @example adaptiveSize({ width: [14, 16], lineHeight: [1.4, 1.67], width: [320, 960], breakpoints: 10 })
 * @returns {string} Resulting adaptive css font-size string
 */
export const adaptiveSize = (config: AdaptiveSizeProps): string => {
  const { size, width, breakpoints, lineHeight, property } = config;

  const cssProp = config && property ? property : 'font-size';

  let mediaQueries = ``;

  for (let i = 1; i < size.length; i += 1) {
    const steps = (width[i] - width[i - 1]) / (breakpoints || 8);

    const startIndex = i === 1 ? width[i - 1] + steps : width[i - 1];
    const endIndex = i === size.length - 1 ? width[i] : width[i] - steps;

    for (let j = startIndex; parseFloat(j.toFixed(0)) <= endIndex; j += steps) {
      const value = interpolateSize(j, width[i - 1], width[i], size[i - 1], size[i]);

      const lh = lineHeight
        ? `line-height: ${interpolateSize(
            j,
            width[i - 1],
            width[i],
            lineHeight[i - 1],
            lineHeight[i]
          ).toFixed(2)};`
        : '';

      const breakpoint = j;
      const fontSize = value;

      mediaQueries += `@media screen and (min-width: ${breakpoint}px) { ${cssProp}: ${fontSize}px; ${lh} }`;
    }
  }

  return `${cssProp}: ${size[0]}px; ${
    lineHeight ? `line-height: ${lineHeight[0]};` : ''
  } ${mediaQueries}`;
};

/* Headlines */
const defaultSettings = {
  width: [768, 1440, 4096],
  breakpoints: 12
};

export const HeadlineHero = `
  font-family: 'Forta', Helvetica, sans-serif;  
  line-height: 1.2;
  ${adaptiveSize({
    size: [80, 200, 300],
    ...defaultSettings
  })}
`;

export const HeadlinePrimary = `
  font-family: 'Forta', Helvetica, sans-serif;  
  line-height: 1.2;
  ${adaptiveSize({
    size: [30, 50, 84],
    ...defaultSettings
  })}
`;

export const HeadlineSecondary = `
  font-family: 'Forta', Helvetica, sans-serif;  
  line-height: 1.2;
  ${adaptiveSize({
    size: [25, 40, 60],
    ...defaultSettings
  })}
`;

export const HeadlineTertiary = `
  font-family: 'Forta', Helvetica, sans-serif;  
  line-height: 1.2;
  ${adaptiveSize({
    size: [20, 30, 36],
    ...defaultSettings
  })}
`;

export const Copy = `
  font-family: 'DIN2014-Regular', Helvetica, sans-serif;
  line-height: 1.8;
  ${adaptiveSize({
    size: [14, 16, 18],
    ...defaultSettings
  })}
`;

export const CopyBig = `
  font-family: 'DIN2014-Regular', Helvetica, sans-serif;
  line-height: 1.5;
  ${adaptiveSize({
    size: [14, 20, 26],
    ...defaultSettings
  })}
`;

export const CopyBold = `
  font-family: 'DIN2014-Regular', Helvetica, sans-serif;
  line-height: 1.2;
  font-weight: bold;
  ${adaptiveSize({
    size: [14, 16, 18],
    ...defaultSettings
  })}
`;

export const CopyCaps = `
  font-family: 'DIN2014-Regular', Helvetica, sans-serif;
  text-transform: uppercase;
  line-height: 1.2;
  font-weight: bold;
  ${adaptiveSize({
    size: [12, 14, 14],
    ...defaultSettings
  })}
`;

export const CopySmall = `
  font-family: 'DIN2014-Regular', Helvetica, sans-serif;
  line-height: 1.2;
  ${adaptiveSize({
    size: [12, 14, 14],
    ...defaultSettings
  })}
`;

export const getTypo = {
  HeadlineHero,
  HeadlinePrimary,
  HeadlineSecondary,
  HeadlineTertiary,
  Copy,
  CopyBig,
  CopyCaps,
  CopySmall,
  CopyBold
};
